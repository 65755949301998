import React, { useState,useEffect } from 'react';


function PwdUnlockButton() {

const fixPassword = 'Password1324@@';
const [showButton, setShowButton] = useState(false);
const [password, setPassword] = useState('')

const handleCancel = () => {
    localStorage.removeItem('ButtonSelected')
}


const handleSubmit = (event) => {
    event.preventDefault();
    if (password == fixPassword){
        setShowButton(true)
    }
  };


  return (
    <>
    <form onSubmit={handleSubmit}>
    <input
      type="password"
      placeholder="Inserisci la password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />
    <button type="submit">Invia</button>
  </form>
  {showButton && (
    <button type="submit" onClick={handleCancel}>
      Cancella Cookie
    </button>
  )}
  </>
  )
}

export default PwdUnlockButton