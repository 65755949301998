export const POST_LIST_REQUEST = 'POST_LIST_REQUEST'
export const POST_LIST_SUCCESS = 'POST_LIST_SUCCESS'
export const POST_LIST_FAIL = 'POST_LIST_FAIL'


export const POST_DETAILS_REQUEST = 'POST_DETAILS_REQUEST'
export const POST_DETAILS_SUCCESS = 'POST_DETAILS_SUCCESS'
export const POST_DETAILS_FAIL = 'POST_DETAILS_FAIL'


export const POST_DELETE_REQUEST = 'POST_DELETE_REQUEST'
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS'
export const POST_DELETE_FAIL = 'POST_DELETE_FAIL'


export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST'
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS'
export const POST_CREATE_FAIL = 'POST_CREATE_FAIL'
export const POST_CREATE_RESET = 'POST_CREATE_RESET'

export const POST_UPDATE_REQUEST = 'POST_UPDATE_REQUEST'
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS'
export const POST_UPDATE_FAIL = 'POST_UPDATE_FAIL'
export const POST_UPDATE_RESET = 'POST_UPDATE_RESET'

export const POST_CREATE_REVIEW_REQUEST = 'POST_CREATE_REVIEW_REQUEST'
export const POST_CREATE_REVIEW_SUCCESS = 'POST_CREATE_REVIEW_SUCCESS'
export const POST_CREATE_REVIEW_FAIL = 'POST_CREATE_REVIEW_FAIL'
export const POST_CREATE_REVIEW_RESET = 'POST_CREATE_REVIEW_RESET'

export const POST_TOP_REQUEST = 'POST_TOP_REQUEST'
export const POST_TOP_SUCCESS = 'POST_TOP_SUCCESS'
export const POST_TOP_FAIL = 'POST_TOP_FAIL'

export const WORK_LIST_REQUEST = 'WORK_LIST_REQUEST'
export const WORK_LIST_SUCCESS = 'WORK_LIST_SUCCESS'
export const WORK_LIST_FAIL = 'WORK_LIST_FAIL'

export const LINK_LIST_REQUEST = 'WORK_LIST_REQUEST'
export const LINK_LIST_SUCCESS = 'WORK_LIST_SUCCESS'
export const LINK_LIST_FAIL = 'WORK_LIST_FAIL'