import React, {useState, useEffect} from 'react'
import { Button,Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listLinks } from '../actions/mediaActions'
import PwdUnlockButton from '../components/PwdUnlockButton'

function TestScreen() {

    const [showButtons, setShowButtons] = useState(true);
    const hasSelectedButton = localStorage.getItem('ButtonSelected');

    const dispatch = useDispatch();
    const linkList = useSelector(state => state.linkList)
    const {error, loading, links} = linkList 
        
    useEffect(() => {
      dispatch(listLinks)

        if (hasSelectedButton) {
            setShowButtons(false);
            }
    },[hasSelectedButton,dispatch])
    
    const acceptButton = () => {
        localStorage.setItem('ButtonSelected', 'true');
        setShowButtons(false)
      }

    


  return (
    <Container>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        {showButtons && (
        <Row className="justify-content-center my-5">
           
           {links.map(link =>(
               <Col>
               <Button id='center-button' type='submit' variant='black' onClick={acceptButton} href={link.link}>{link.name}</Button>
              </Col>
           ))}
            
        </Row>
        )}
        <PwdUnlockButton></PwdUnlockButton>

    </Container>
  )
}

export default TestScreen