import React, {useState, useEffect} from 'react'
import {Form, Button, Row, Col, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { listMyOrders } from '../actions/orderActions'


function ProfileScreen({history}) { //History viene passata tra brackets per essere considerata una funzione 

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')      
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails) 
    const {error, loading, user} = userDetails //prendo le informazioni dell'utente dal reducer nello store

    const userLogin = useSelector(state => state.userLogin) 
    const { userInfo } = userLogin //capisco dalle informazioni nello state se sono loggato o meno

    const userUpdateProfile = useSelector(state => state.userUpdateProfile) 
    const { success } = userUpdateProfile

    const orderListMy = useSelector(state => state.orderListMy)
    const { loading: loadingOrders, error: errorOrders, orders} = orderListMy


    useEffect(() => {  
       
      if (!userInfo) {
        history.push("/login") 
      }else{
        if( !user || !user.name || success || userInfo._id !== user._id){//triggera anche quando il valore di userDetails e Diverso da userInfo 
            dispatch({ type: USER_UPDATE_PROFILE_RESET })
            dispatch(getUserDetails('profile')) //riempie il parametro id nell'argomento del metodo Details in ACTION
            dispatch(listMyOrders())
        } else {
            setName(user.name)
            setEmail(user.email)
        }
      }
    }, [dispatch, history, userInfo, user, success])


    const submitHandler = (e) => {
       e.preventDefault()

       if(password !== confirmPassword){
         setMessage('Passwords do not match')
       } else {
         dispatch(updateUserProfile({
            'id': user._id,
            'name': name,
            'email': email,
            'password': password
         }))
         setMessage('')

       }
       /* console.log(email, password, dispatch)
       console.log(typeof email, typeof password) */
    } 



  return (
    <Row>
       
        <Col md={3}>
            <h2>User Profile</h2>

            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>} {/* se esiste un errore allora questo operatore ternario mostra il bracket */}
            {loading && <Loader/>}
            <Form onSubmit={submitHandler}>

          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
              <Form.Control
              required
              type='name'
              placeholder='Enter name'  //L'immagine risulta prepopolata per via dell'USEEFFECT che carica i valori dell'utente 
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='my-3'
              ></Form.Control>
          </Form.Group>
          
          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control 
              required
              type='email'
              placeholder='Enter Email'
              value ={email}
              onChange={(e) => setEmail(e.target.value)} //ogni volta che il valore cambia, cambia anche il valore dell'useState 
              className='my-3'
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Password </Form.Label>
            <Form.Control
              
              type='password'
              placeholder='Enter Password'
              value ={password}
              onChange={(e) => setPassword(e.target.value)} 
              className='my-3'
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Confirm Password </Form.Label>
            <Form.Control
              /* required */ //non ho bisogno del passwordField required
              type='password'
              placeholder='Confirm Password'
              value ={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)} 
              className='my-3'
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary' className='my-3' >Update</Button>
       </Form>

        </Col>

        <Col md={9}>
           <h2>My Orders</h2>
           {loadingOrders ? (
            <Loader/>
           ) : errorOrders ? (
              <Message variant='danger'>{errorOrders}</Message>
           ) : (
              <Table striped responsive className='table-sm'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Total</th>
                      <th>Paid</th>
                      <th>Delivered</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {orders.map(order => (
                     <tr key={order._id}>
                       <td>{order._id}</td>
                       <td>{order.createdAt.substring(0, 10)}</td>
                       <td>${order.totalPrice}</td>
                       <td>{order.isPaid ? order.paidAt.substring(0, 10) : (
                        <i className='fas fa-times' style={{ color: 'red'}}></i>
                       )}</td>
                       <td>
                          <LinkContainer to={`/order/${order._id}`}>
                            <Button className='btn-sm'>Details</Button>
                          </LinkContainer>
                       </td>
                     </tr>
                    ))}
                  </tbody>
              </Table>
           )} 
       </Col>
    </Row>
  )
}

export default ProfileScreen