import React, { useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'
import { listProducts } from '../actions/productActions'
import AnimatedPage from '../components/AnimatedPage'




function ShopScreen({ history }) {

  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch()
  const productList = useSelector(state => state.productList)
  const {error, loading, products, page, pages} = productList

  let keyword = history.location.search
  
  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 3000)
    dispatch(listProducts(keyword))
  
 }, [dispatch, keyword])
 

  return (
    <div>
       { loader ? (<Loader/>) : (
    
    <AnimatedPage>
    <div>
      {/* {!keyword && <ProductCarousel/>} */}
        
        <h1 className='mt-5'>Latest Products</h1>
        {loading ? <Loader/>
          : error ? <Message variant='danger' >{error}</Message>
                    
            :
          <div>
            <Row> 
            {products.map(product => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                    <Product product={product}/>
                </Col>

                ))}
            </Row>
              <Paginate page={page} pages={pages} keyword={keyword}/>
          </div> 
          }
    </div>
    </AnimatedPage>)}
    </div>
  )
}

export default ShopScreen