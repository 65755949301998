import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Form, Button, Row, Col } from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { register} from '../actions/userActions'
import ReCAPTCHA from "react-google-recaptcha";

function RegisterScreen({location, history}) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')        //useState per tenere i valori 
    const [message, setMessage] = useState('')
    

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/' //cerca una query string e ne prende il secondo value

    const userRegister = useSelector(state => state.userRegister) 
    const {error, loading, userInfo} = userRegister



    useEffect(() => {      //Se ho già 
      if (userInfo) {
        history.push(redirect) //redirect all
      }
    }, [history, userInfo, redirect])


    const submitHandler = (e) => {
       e.preventDefault()

       if(password !== confirmPassword){
         setMessage('Passwords do not match')
       } else {
        dispatch(register(name, email, password))
       }
       /* console.log(email, password, dispatch)
       console.log(typeof email, typeof password) */
    } 




  return (
    
    <FormContainer>
       <h1>Register</h1>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>} {/* se esiste un errore allora questo operatore ternario mostra il bracket */}
        {loading && <Loader/>}

       <Form onSubmit={submitHandler}>

          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
              <Form.Control
              required
              type='name'
              placeholder='Enter name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='my-3'
              ></Form.Control>
          </Form.Group>
          
          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control 
              required
              type='email'
              placeholder='Enter Email'
              value ={email}
              onChange={(e) => setEmail(e.target.value)} //ogni volta che il valore cambia, cambia anche il valore dell'useState 
              className='my-3'
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Password </Form.Label>
            <Form.Control
              required
              type='password'
              placeholder='Enter Password'
              value ={password}
              onChange={(e) => setPassword(e.target.value)} //ogni volta che il valore cambia, cambia anche il valore dell'useState 
              className='my-3'
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Confirm Password </Form.Label>
            <Form.Control
              required
              type='password'
              placeholder='Confirm Password'
              value ={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)} //ogni volta che il valore cambia, cambia anche il valore dell'useState 
              className='my-3'
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary' className='my-3' >Register</Button>
       </Form>

       <ReCAPTCHA
    sitekey={process.env.REACT_APP_SITE_KEY}
    onChange={console.log('ciao')}
  />  

       <Row className='py-3'> 
      <Col>
      Have an account? <Link
        to={redirect ? `/login?redirect=${redirect}` : '/login' }>Sign In
      </Link>
      </Col>
    </Row>

    </FormContainer>

  )
}

export default RegisterScreen
